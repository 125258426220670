import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import AdminModule from "@/store/module/admin/AdminModule";
import UserModule from "@/store/module/user/UserModule";
import { routePath } from "@/router/router-constants";
import RegistrationForm from "@/components/user/auth/RegistrationForm.vue";
let Auth = class Auth extends Vue {
    constructor() {
        super(...arguments);
        this.email = {
            type: "input",
            ref: "email",
            value: "",
            showOnlyForSuperAuth: false,
            rules: [Validation.required, Validation.email],
        };
        this.password = {
            type: "input",
            ref: "password",
            value: "",
            showOnlyForSuperAuth: false,
            passwordVisible: false,
            mode: false,
            rules: [Validation.required],
        };
        this.isAdminSignIn = true;
        this.isUserSignIn = false;
        this.isAuth = true;
        this.isPasswordRecovery = false;
        this.flowText = "Регистрация";
    }
    get title() {
        if (this.isPasswordRecovery)
            return "Восстановление пароля";
        if (this.isAuth)
            return "Авторизация";
        return "Регистрация";
    }
    changeFlow() {
        this.isAuth = !this.isAuth;
        this.flowText = this.flowText === "Вход" ? "Регистрация" : "Вход";
    }
    async login() {
        const form = [this.email, this.password];
        let hasError = Validation.validateWithView(form, this.$refs);
        if (hasError === true)
            return;
        const action = this.isUserSignIn ? UserModule.types.actions.LOGIN : AdminModule.types.actions.LOGIN;
        await this.$store.dispatch(action, {
            email: this.email.value,
            password: this.password.value,
        });
        this.$notify({
            type: "success",
            title: "Авторизация прошла успешно!",
        });
        this.$router.push(routePath.ROOT);
    }
    pickLoginType(type) {
        if (type === "admin") {
            this.isAdminSignIn = true;
            this.isUserSignIn = false;
        }
        else {
            this.isUserSignIn = true;
            this.isAdminSignIn = false;
        }
    }
    forgotPassword() {
        // this.isUserSignIn = true
        this.isAuth = !this.isAuth;
        this.isPasswordRecovery = true;
    }
    async mounted() { }
};
Auth = __decorate([
    Component({
        components: {
            RegistrationForm,
        },
    })
], Auth);
export default Auth;
