import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import ApproveDialog from "@/components/shared/Approve.vue";
let RegistrationForm = class RegistrationForm extends Vue {
    constructor() {
        super(...arguments);
        this.name = {
            ref: "name",
            type: "input",
            label: "Имя",
            value: "",
            placeholder: "Роман",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.surname = {
            ref: "surname",
            type: "input",
            label: "Фамилия",
            value: "",
            placeholder: "Марков",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.patronymic = {
            ref: "patronymic",
            type: "input",
            label: "Отчество",
            value: "",
            placeholder: "Алексеевич",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.birthdate = {
            ref: "birthdate",
            type: "datepicker",
            label: "Дата рождения",
            value: "",
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.email = {
            ref: "email",
            type: "input",
            label: this.isPasswordRecovery ? "Введите ваш e-mail адрес" : "Введите ваш актуальный e-mail адрес",
            placeholder: "example@mail.ru",
            value: "",
            showOnlyForSuperAuth: false,
            rules: [Validation.required, Validation.email],
        };
        this.emailCode = {
            ref: "emailCode",
            type: "input",
            label: "Введите код, который пришел вам на почту",
            value: "",
            placeholder: "000000",
            showOnlyForSuperAuth: false,
            rules: [Validation.required, Validation.int],
        };
        this.password = {
            ref: "vpassword",
            type: "input",
            label: "Введите новый пароль",
            value: "",
            placeholder: "********",
            mode: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required, (value) => value.length >= 6 || "Пароль должен состоять минимум из 6 символов"],
        };
        this.passwordRepeat = {
            ref: "vpasswordRepeat",
            type: "input",
            label: "Введите новый пароль еще раз",
            value: "",
            placeholder: "********",
            mode: false,
            showOnlyForSuperAuth: false,
            rules: [
                Validation.required,
                (value) => value.length >= 6 || "Пароль должен состоять минимум из 6 символов",
                (value) => value === this.password.value || "Пароли должны совпадать",
            ],
        };
        this.baseFields = [this.surname, this.name, this.patronymic, this.birthdate];
        this.verification = {
            user: null,
            timer: {
                show: false,
                interval: null,
                start: null,
                time: "01:00",
            },
            resendButtonDisabled: true,
        };
        this.currentStep = 0;
    }
    get steps() {
        if (this.isPasswordRecovery)
            return [this.hasEmailAtRegisteredUsers, this.verifyCodeWithSignIn, this.updatePassword];
        return [this.checkout, this.sendVerificationCode, this.verifyCode, this.registration];
    }
    async hasByNSPB() {
        return this.$store.dispatch(UserModule.types.actions.IS_USER_EXISTED_BY, {
            name: this.name.value,
            surname: this.surname.value,
            patronymic: this.patronymic.value,
            birthdate: this.birthdate.value,
        });
    }
    async hasEmailAtRegisteredUsers() {
        const hasError = Validation.validateWithView([this.email], this.$refs);
        if (hasError)
            return false;
        const response = await this.$store.dispatch(UserModule.types.actions.IS_USER_EXISTED_BY, {
            email: this.email.value,
            isRegistered: true,
        });
        if (!response.status) {
            return this.$notify({
                title: "Пользователь с таким email не найден",
                duration: 5000,
                type: "error",
            });
        }
        return await this.sendVerificationCode(true);
    }
    async checkout() {
        const hasError = Validation.validateWithView(this.baseFields, this.$refs);
        if (hasError)
            return false;
        const user = await this.$store.dispatch(UserModule.types.actions.FIND_USER_BY, {
            name: this.name.value,
            surname: this.surname.value,
            patronymic: this.patronymic.value,
            birthdate: this.birthdate.value,
        });
        if (!user) {
            this.$notify({
                title: "Пользователь не найден.",
                duration: 3000,
                type: "warning",
            });
            return false;
        }
        this.verification.user = user;
        return true;
    }
    async hasEmail() {
        const response = await this.$store.dispatch(UserModule.types.actions.IS_USER_EXISTED_BY, {
            email: this.email.value,
            excludeId: this.verification.user.id,
        });
        if (response.status) {
            this.$notify({
                title: "Пользователь с таким email уже зарегестрирован",
                duration: 3000,
                type: "warning",
            });
            return true;
        }
        return false;
    }
    async sendVerificationCode(skipValidation = false) {
        if (!skipValidation) {
            const hasError = Validation.validateWithView([this.email], this.$refs);
            if (hasError)
                return false;
            if (await this.hasEmail())
                return false;
        }
        await this.$store.dispatch(UserModule.types.actions.SEND_EMAIL_CODE, {
            id: this.verification?.user?.id,
            email: this.email.value,
        });
        this.verification.resendButtonDisabled = true;
        this.verification.timer.start = new Date();
        this.verification.timer.show = true;
        this.$notify({
            title: `Код подтверждения отправлен на ${this.email.value}`,
            duration: 5000,
            type: "success",
        });
        this.verification.timer.interval = setInterval(() => {
            const secondsBeforeTimerDone = 60 - Math.floor((Date.now() - this.verification.timer.start.getTime()) / 1000);
            if (secondsBeforeTimerDone <= 0) {
                clearInterval(this.verification.timer.interval);
                this.verification.timer.interval = null;
                this.verification.timer.show = false;
                this.verification.resendButtonDisabled = false;
                return;
            }
            const secondsView = secondsBeforeTimerDone < 10 ? `00:0${secondsBeforeTimerDone}` : `00:${secondsBeforeTimerDone}`;
            this.verification.timer.time = secondsView;
        }, 1000);
        return true;
    }
    async verifyCode() {
        const hasError = Validation.validateWithView([this.emailCode], this.$refs);
        if (hasError)
            return false;
        await this.$store.dispatch(UserModule.types.actions.VERIFY_EMAIL_CODE, {
            userId: this.verification.user.id,
            email: this.email.value,
            code: parseInt(this.emailCode.value),
            login: false,
        });
        return true;
    }
    async registration() {
        const hasError = Validation.validateWithView([this.password, this.passwordRepeat], this.$refs);
        if (hasError)
            return false;
        await this.$store.dispatch(UserModule.types.actions.REGISTER_EXISTED_USER, {
            id: this.verification.user.id,
            email: this.email.value,
            password: this.passwordRepeat.value,
        });
        this.$notify({
            title: "Регистрация прошла успешно!",
            duration: 3000,
            type: "success",
        });
        this.$router.push("/");
        return true;
    }
    async verifyCodeWithSignIn() {
        const hasError = Validation.validateWithView([this.emailCode], this.$refs);
        if (hasError)
            return false;
        await this.$store.dispatch(UserModule.types.actions.VERIFY_EMAIL_CODE, {
            email: this.email.value,
            code: parseInt(this.emailCode.value, 10),
            login: true,
        });
        this.verification.user = this.$store.getters[UserModule.types.getters.USER];
        return true;
    }
    async updatePassword() {
        let hasError = false;
        try {
            hasError = Validation.validateWithView([this.password, this.passwordRepeat], this.$refs);
        }
        catch (e) { }
        if (hasError)
            return false;
        await this.$store.dispatch(UserModule.types.actions.UPDATE_USER, {
            user: {
                password: this.passwordRepeat.value,
            },
        });
        this.$notify({
            title: "Пароль успешно обновлен!",
            duration: 3000,
            type: "success",
        });
        this.$router.push("/");
        return true;
    }
    async nextStep() {
        if (!this.steps[this.currentStep])
            return;
        const status = await this.steps[this.currentStep]();
        if (status)
            this.currentStep++;
    }
    setMock() {
        this.name.value = "Сергей";
        this.surname.value = "Павлович";
        this.patronymic.value = "Александрович";
        this.birthdate.value = "2022-12-24";
        this.email.value = "wow-aka.moy@yandex.ru";
    }
    mounted() {
        //this.setMock()
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], RegistrationForm.prototype, "isPasswordRecovery", void 0);
RegistrationForm = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
        directives: {
            mask,
        },
    })
], RegistrationForm);
export default RegistrationForm;
